module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Umar Nizamani","short_name":"Umar Nizamani","start_url":"/","background_color":"white","theme_color":"#5183f5","display":"minimal-ui","icon":"static/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3eaf2cf804c9ef0c837fc4042f296bae"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"quality":70,"withWebp":true,"withAvif":true,"disableBgImageOnAlpha":true,"markdownCaptions":true,"linkImagesToOriginal":true,"showCaptions":false,"backgroundColor":"white","loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
